.notice {
  position: relative;
  padding: rhythm($space) rhythm(1.5) rhythm($space) rhythm($space);
  background: $notice-background-color;
  color: $notice-text-color;

  h2 {
    @include adjust-font-size-to($h4-font-size, $h4-line-height);
    margin-bottom: 0;
    color: $notice-text-color;
  }

  strong {
    @include adjust-font-size-to($h4-font-size, $h4-line-height);
    margin-right: rhythm($space, $h4-font-size);
  }

  p {
    @extend %small;
    margin-bottom: 0;
  }

  a {
    color: $notice-text-color;
    text-decoration: underline;

    &:focus,
    &:hover {
      text-decoration: none;
    }
  }

  &.is-active {
    display: block;
  }
}

.notice-divider {
  color: $notice-divider-color;
  margin: 0 rhythm(.25);
}

.close-notice {
  @extend %icon;
  @include adjust-font-size-to(30px, 1);
  position: absolute;
  top: 50%;
  margin-top: rhythm(-.625, 30px);
  right: rhythm($space, 30px);
  color: $notice-text-color;
  cursor: pointer;
  font-weight: bold;

  .notice & {
    text-decoration: none;
  }
}
