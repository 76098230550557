@include on-desktop($breakpoint: $listing-desktop-breakpoint, $columns: 16) {
  @include with-parent-column(8) {
    .listing__primary-info {
      @extend %listing-desktop-column-base;
      width: column-width(4);
    }

    .listing__secondary-info {
      @extend %listing-desktop-column-base;
      @include column-gutter;
      width: column-width(4);
    }
  }

  %listing-desktop-column-base {
    @include column-base;
  }

  %listing-desktop-column-gutter {
    @include column-gutter;
  }

  .listing::before {
    // Reposition Horizontal Dividing Line
    left: column-distance(11, $with-gutter: false) + column-gutter() / 2;
  }

  .listing__photo {
    width: column-width(3);
  }

  .listing__profile {
    @extend %listing-desktop-column-gutter;
    width: column-width(8);
  }

  .listing__name {
    @include adjust-font-size-to($h2-font-size, $h2-line-height);
    margin-bottom: rhythm(.5, $h2-font-size);
  }

  .listing__primary-info,
  .listing__secondary-info {
    @include adjust-font-size-to($base-font-size);
  }

  .listing__openings {
    @extend %listing-desktop-column-gutter;
    width: column-width(5);
  }
}
