// ----------------------------------------------------------------------------
// Profile
// The Provider profile page layout.
// ----------------------------------------------------------------------------

.profile {
  position: relative;
  padding-top: rhythm(3);

  .row {
    position: relative;
    z-index: 10;
  }
}

.profile__photo {
  @extend %left;
  width: rhythm(3);
  margin-right: rhythm(.5);

  .small-box {
    padding: rhythm(.25);
  }

  img {
    display: block;
  }
}

.profile__breadcrumbs {
  margin-bottom: rhythm(.5, $small-font-size);
}

.profile__name {
  margin-bottom: 0;
}

.profile__body {
  @extend %clearfix;
}

.profile__map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  height: rhythm(2.5);
  border-bottom: 1px solid $border-color;

  .generatedcontent.pointerevents & {
    height: rhythm(4);
    border-bottom: 0;

    &::before {
      background-image: linear-gradient($profile-map-background-color-start 0%, $profile-map-background-color-end 80%);
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
      display: block;
      width: 100%;
      height: rhythm(4);
      content: " ";
      pointer-events: none;
    }
  }
}

// ----------------------------------------------------------------------------
// On Tablet
// ----------------------------------------------------------------------------

@include on-tablet-up {
  .profile__photo {
    width: rhythm(5);

    .small-box {
      padding: rhythm(.5);
    }
  }

  .profile__body {
    padding-top: rhythm(.5);
  }

  .profile__name {
    @include adjust-font-size-to($h1-font-size, $h1-line-height);
  }
}

// ----------------------------------------------------------------------------
// On Desktop
// ----------------------------------------------------------------------------

@include on-desktop {
  .profile {
    padding-top: rhythm(5);
  }

  .profile__photo {
    width: auto;
    margin-top: rhythm(-2.75);
  }

  .profile__body {
    padding-top: 0;
  }

  .profile__map {
    height: rhythm(4);

    .generatedcontent.pointerevents & {
      height: rhythm(6);
    }
  }

  .profile__info {
    dt,
    dd {
      @include inline-block;
      margin-bottom: 0;
      vertical-align: baseline;
    }

    dt {
      float: none;
      margin-right: 0;
      margin-left: rhythm($space);

      &:first-child {
        margin-left: 0;
      }
    }

    dd {
      margin-left: 0;
    }
  }
}
