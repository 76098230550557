.search-results-views {
  padding-top: rhythm(1.5);
  // This lines up the filterbox with the top of the table.
  margin-bottom: rhythm(1, $offset: -1px);
}

.search-results__pagination {
  @extend .pagination;
  li {
    span, a {
      width: rhythm(3);
      display: block;
      text-align: center;
    }
  }
}

.filterbox {
  @include on-desktop {
    &.stuck {
      position: fixed;
      top: rhythm(1);
    }
  }
}
