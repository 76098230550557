// ----------------------------------------------------------------------------
// LocalMed Icon Helpers
// ----------------------------------------------------------------------------

@mixin lm-circle-icon-mixin($background-color, $foreground-color) {
  position: relative;
  width: .7em;
  height: .7em;
  background-color: $background-color;
  color: $foreground-color;
  border-radius: 50%;
  font-size: 2em;
  padding: .35em;
  line-height: 1em;

  > [class^="icon-"] {
    position: absolute;
    text-align: center;
    width: 100%;
    height: 1px;
    top: 10%;
    left: 0;
    overflow: visible;
  }
}

.lm-circle-icon--red-white {
  @include lm-circle-icon-mixin($red, $white);
}

.lm-circle-icon--blue-white {
  @include lm-circle-icon-mixin($blue, $white);
}

.lm-circle-icon--green-white {
  @include lm-circle-icon-mixin($green, $white);
}

.lm-circle-icon--charcoal-gray-white,
.lm-circle-icon--black-white {
  @include lm-circle-icon-mixin($charcoal, $white);
}

.lm-circle-icon--gray-white {
  @include lm-circle-icon-mixin($charcoal-gray, $white);
}
