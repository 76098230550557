.responsive-table .text-right {
  @include on-mobile {
    text-align: left;
  }
}

.extra-wide-button {
  padding-left: 0;
  padding-right: 0;
  width: 60%;
}

.small-media-obj {
  margin-right: rhythm($space);
}

.actions-td {
  @include on-tablet-up {
    border-left: 1px dashed $border-color;
    text-align: center;
  }
}

.blue-feature-list-content {
  @include with-feature(generatedcontent) {
    ul li:before {
      color: $blue;
    }
  }
}

.box-section {
  margin-left: rhythm(-$space);
  margin-right: rhythm(-$space);
  padding: rhythm($space);
  border-top: 1px solid $border-color;

  &:first-child {
    padding-top: 0;
    border-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  .box & {
    @media screen and (min-width: $tablet-breakpoint) {
      margin-left: rhythm(-$full-space);
      margin-right: rhythm(-$full-space);
      padding: rhythm($full-space);

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

.affiliation-media {
  @include spacing(.25);

  .media-body {
    padding-top: rhythm(.125);
  }
}

.highlight-old {
  @extend %error-highlight;
  text-decoration: line-through;
}

.highlight-new {
  @extend %success-highlight;
}
