// ----------------------------------------------------------------------------
// LocalMed Typography Utility Classes
// ----------------------------------------------------------------------------

.lm-uppercase {
  @extend %caps;
}

.lm-bold {
  @extend %strong;
}

.lm-text-light {
  font-weight: 200;
}

.lm-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

// temporary hack

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  .lm-text-light {
    font-family: $meta-normal-font-family;
    font-weight: 200;
    font-size: .9em;
  }
}

.lm-meta-normal {
  font-family: $meta-normal-font-family;

  strong {
    font-family: $meta-bold-font-family;
  }
}

.lm-meta-bold {
  font-family: $meta-bold-font-family;
}

.lm-no-letter-spacing {
  letter-spacing: 0;
}

.lm-text-right {
  text-align: right;
}
