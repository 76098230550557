// ----------------------------------------------------------------------------
// LocalMed Text Color Helpers
// ----------------------------------------------------------------------------

.lm-text-color-red,
a.lm-text-color-red {
  color: $red;
}

.lm-text-color-blue,
a.lm-text-color-blue {
  color: $blue;
}

.lm-text-color-green,
a.lm-text-color-green {
  color: $green;
}

.lm-text-color-charcoal,
a.lm-text-color-charcoal,
.lm-text-color-black,
a.lm-text-color-black {
  color: $charcoal;
}

.lm-text-color-charcoal-gray,
a.lm-text-color-charcoal-gray,
.lm-text-color-gray,
a.lm-text-color-gray {
  color: $charcoal-gray;
}

.lm-text-color-white,
a.lm-text-color-white {
  color: $white;
}
