$combobox-font-size: $input-font-size;
$combobox-border-radius: $input-border-radius;
$combobox-border-color: $input-border-color;
$combobox-hover-border-color: darken($combobox-border-color, 15%);
$combobox-background-color: #fff;
$combobox-gray-form-background-color: $button-background-color;
$combobox-text-color: $input-text-color;
$combobox-highlighted-color: $button-background-color;
$combobox-disabled-color: #666;
$combobox-disabled-background-color: #f4f4f4;

.combobox {
  position: relative;
}

.combobox__button {
  @include adjust-font-size-to($combobox-font-size, $button-line-height);
  @include ellipsis;
  display: inline-block;
  margin: 0;
  padding: rhythm(0.125, $combobox-font-size) rhythm($input-horizontal-padding, $combobox-font-size);
  border-radius: $combobox-border-radius;
  color: $combobox-text-color;
  text-decoration: none;
  font-weight: normal;
  font-family: $base-font-family;
  cursor: pointer;
  text-align: left;
  outline: none;
}

.combobox__button,
.combobox__input[type="text"] {
  @include gradient-background($combobox-background-color);
  box-sizing: border-box;
  width: 100%;
  border: 1px solid $combobox-border-color;
  box-shadow: 0 $button-box-shadow-size 0 $input-box-shadow-color;
  transition-property: border-color, box-shadow, background;
  padding-right: em-rhythm(1.25);

  .field-input & {
    margin-bottom: 0;
  }

  &:hover {
    border-color: $combobox-border-color;
    box-shadow: 0 $button-box-shadow-size 0 $combobox-border-color;
  }

  &:focus {
    border-color: $blue;
  }

  &:disabled {
    color: $input-disabled-text-color;
    cursor: default;
    cursor: not-allowed;
    background: $input-disabled-background-color;
    box-shadow: none;
  }
}

.combobox__placeholder {
  color: $muted-color;
}

.combobox__indicator {
  position: absolute;
  top: 50%;
  right: 1px;
  width: em-rhythm(1.25);
  height: em-rhythm(1.25);
  line-height: em-rhythm(1.25);
  margin: 0;
  padding: 0;
  text-align: center;
  transform: translateY(-50%);
}

.combobox__indicator--toggle {
  color: $muted-color;
  border: 0;
  background: none;
  box-shadow: none;
  outline: none;
  transition: all 250ms ease-out;

  &:hover {
    color: $blue;
  }

  &:disabled {
    color: $muted-color;
    cursor: default;
    cursor: not-allowed;
  }
}

.combobox__popper {
  width: 100%;
}

.combobox__menu {
  width: 100%;
  max-height: em-rhythm(12);
  margin-top: -1px;
  box-sizing: border-box;
  color: $combobox-text-color;
  background: $combobox-background-color;
  border: 1px solid $blue;
  border-top-color: $border-color;
  border-radius: 0 0 $combobox-border-radius $combobox-border-radius;
  box-shadow: rgba(0, 0, 0, 0.19) 0 10px 30px 0, rgba(0, 0, 0, 0.23) 0 6px 10px 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 2;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: 0 0;
  transition: all 0 ease-out;
  pointer-events: none;
}

.combobox__option {
  padding: em-rhythm(0.125) em-rhythm($input-horizontal-padding);
  margin: 0;
  color: $link-color;
  cursor: pointer;
}

.combobox__option--selected {
  background-color: $combobox-highlighted-color;
}

.combobox__option--highlighted {
  background-color: $combobox-highlighted-color;
  color: $link-hover-color;
}

.combobox__section-title {
  @include adjust-font-size-to($small-font-size, 1);
  padding: em-rhythm(0.125, $small-font-size) em-rhythm($input-horizontal-padding, $small-font-size);
  margin: 0;
  color: $muted-color;

  &:not(:first-child) {
    margin-top: em-rhythm(0.3125, $small-font-size);
  }
}

.combobox__message {
  padding: em-rhythm(0.125) em-rhythm($input-horizontal-padding);
  margin: 0;
}

// -------------------------------------------------------------------------------------------------
// Open State
// -------------------------------------------------------------------------------------------------

.combobox--open {
  z-index: 500;

  .combobox__menu {
    opacity: 1;
    transform: scaleY(1);
    transition-duration: 250ms;
    pointer-events: auto;
  }

  .combobox__button,
  .combobox__input[type="text"] {
    border-color: $blue;
    border-radius: $combobox-border-radius $combobox-border-radius 0 0;
    background: $combobox-background-color;
  }

  .combobox__indicator--toggle {
    transform: translateY(-50%) rotate(180deg);
  }
}

// -------------------------------------------------------------------------------------------------
// Gray Form Context
// -------------------------------------------------------------------------------------------------

.gray-form {
  .combobox__button,
  .combobox__input[type="text"] {
    @include gradient-background($combobox-gray-form-background-color);

    &:disabled {
      background: $input-disabled-background-color;
    }
  }

  .combobox--open {
    .combobox__button,
    .combobox__input[type="text"] {
      background: $combobox-background-color;
    }
  }
}

// -------------------------------------------------------------------------------------------------
// Searchbar Context
// -------------------------------------------------------------------------------------------------

.searchbar {
  .combobox__button,
  .combobox__input[type="text"] {
    &:hover {
      border-color: $charcoal;
      box-shadow: none;
    }

    &:focus {
      border-color: $charcoal;
    }
  }

  .combobox--open {
    .combobox__button,
    .combobox__input[type="text"]:focus {
      border-color: $blue;
    }
  }
}

// -------------------------------------------------------------------------------------------------
// Hero Form Context
// -------------------------------------------------------------------------------------------------

.lm-crux-home-hero__form {
  .combobox__button,
  .combobox__input[type="text"] {
    border-radius: 0 0 $border-radius $border-radius;
    box-shadow: none;
    height: 37px;

    @include on-desktop {
      border-radius: 0;
    }
  }

  .combobox__indicator {
    height: 35px;
    line-height: 35px;
  }

  .combobox--open {
    .combobox__button,
    .combobox__input[type="text"] {
      border-radius: 0;
    }
  }
}

// -------------------------------------------------------------------------------------------------
// Filter Form Context
// -------------------------------------------------------------------------------------------------

.filter-form {
  .combobox__button,
  .combobox__input[type="text"] {
    border: 1px solid $combobox-border-color;
    border-radius: $combobox-border-radius;
    background: $combobox-background-color;
    box-shadow: none;
    padding-left: em-rhythm($input-horizontal-padding, $combobox-font-size);

    &:hover {
      border-color: $combobox-border-color;
    }

    &:focus {
      border-color: $blue;
    }

    @include on-desktop {
      margin-top: 0;
    }
  }

  .combobox--open {
    .combobox__button,
    .combobox__input[type="text"] {
      border-color: $blue;
      border-radius: $combobox-border-radius $combobox-border-radius 0 0;
    }
  }
}
