.svg-icon {
  line-height: 1em;
  // Cascade fill color for SVGs: https://css-tricks.com/cascading-svg-fill-color/
  fill: currentColor;
}

.svg-icon--baseline {
  vertical-align: baseline;
  // An attempt to visually align the icons to the baseline by default.
  transform: translateY(em(3px));

  &.svg-icon--spin {
    animation: svgiconspinbaseline 1.25s infinite linear;
  }
}

@keyframes svgiconspinbaseline {
  0% {
    transform: translateY(em(3px)) rotate(0deg);
  }

  100% {
    transform: translateY(em(3px)) rotate(360deg);
  }
}

.svg-icon--center {
  vertical-align: middle;

  &.svg-icon--spin {
    animation: svgiconspincenter 1.25s infinite linear;
  }
}

@keyframes svgiconspincenter {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

