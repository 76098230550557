// This customizes the appearance of the scrollbar + forces it to be visible
// when on a touch device, which is useful over elements with overflow: auto.
//
// Unfortunately, this only works on Webkit browsers...
.scrollbar {
  &::-webkit-scrollbar {
    width: 15px;
    height: 15px;
    background-color: rgba($background-color, .5);
  }

  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: $muted-color;
    // This decreases the overall size of the thumb
    border: 5px solid rgba(#fff, 0);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    &:horizontal {
      border-top: 1px solid $background-color;
      border-bottom: 1px solid $background-color;
    }

    &:vertical {
      border-left: 1px solid $background-color;
      border-right: 1px solid $background-color;
    }
  }
}
