// ----------------------------------------------------------------------------
// Location Container
// ----------------------------------------------------------------------------

.location-container {
  position: relative;
  z-index: 1;

  @include on-desktop {
    min-height: rhythm(20);
  }
}

// ----------------------------------------------------------------------------
// Location Box
// ----------------------------------------------------------------------------

.location-box {
  position: relative;
  z-index: 2;

  @include on-desktop {
    box-shadow: 0 4px 20px $location-box-box-shadow-color;
    padding: 0;
    border: 0;
  }
}

.location-box__header,
.location-box__content {
  padding-bottom: rhythm($space);
}

.location-box__footer,
.location-box__content {
  padding-top: rhythm($space);
}

.location-box__header,
.location-box__footer,
.location-box__content {
  @include on-desktop {
    padding: rhythm($full-space);
  }
}

.location-box__message {
  margin: 0;
  border-right: 0;
  border-left: 0;
}

// ----------------------------------------------------------------------------
// Location List
// ----------------------------------------------------------------------------

.location-box__list {
  @extend %no-bullets;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0 rhythm(-$space);
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;

  @include on-desktop {
    margin-right: 0;
    margin-left: 0;
  }

  li {
    border-top: 1px solid $border-color;

    &:first-child {
      border-top: 0;
    }
  }
}

.location-box__list-item {
  position: relative;
  display: block;
  padding: rhythm($space);
  color: $text-color;

  h2 {
    color: $link-color;
  }

  .box & {
    @include on-desktop {
      padding-right: rhythm($full-space);
      padding-left: rhythm($full-space);
    }
  }

  &::before {
    @extend %icon;
    @include adjust-font-size-to(20px, 1);
    @include opacity(0);
    @include icon-content("angle-right");
    transition-property: all;
    transition-duration: $out-duration;
    transition-timing-function: ease-out;
    position: absolute;
    top: 50%;
    right: rhythm(1, 20px);
    margin-top: rhythm(-.375);
    color: $strong-color;
  }

  &:focus,
  &:hover {
    background: $background-color;
    color: $text-color;

    h2 {
      color: $link-hover-color;
    }

    .step-action {
      color: $link-hover-color;
    }

    &::before {
      @include opacity(1);
      transition-duration: $in-duration;
      right: rhythm($space, 20px);
    }
  }
}
