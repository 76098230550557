.document-placeholder {
  &.is-loading {
    &::before {
      display: inline-block;
      width: 32px;
      height: 32px;
      background: url("../images/loading.gif") 50% 50% no-repeat;
      content: " ";
      vertical-align: middle;
      margin-right: rhythm($space);
    }
  }
}

.document-preview {
  @include inline-block;
  position: relative;
  border: 2px solid $border-color;

  .button {
    position: absolute;
    top: 50%;
    right: rhythm($full-space);
    left: rhythm($full-space);
    display: block;
    margin-top: rhythm(-$space);
    text-align: center;
  }

  img {
    display: block;
  }

  &:focus,
  &:hover {
    border-color: $link-color;
  }
}
