@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@mixin fade-in($arg) {
  $keyframe-name: fade-in;
  $duration: $arg;
  animation: $keyframe-name $duration;
}

.lm-fade-in {
  @include fade-in(2s);
}
