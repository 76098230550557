// ----------------------------------------------------------------------------
// Hero
// ----------------------------------------------------------------------------

.hero {
  text-align: center;
  font-size: .55em;

  @include on-tablet-up {
    font-size: .85em;
  }

  @include on-desktop {
    font-size: 1em;
  }
}

.hero-section-title {
  @include adjust-font-size-to($h3-font-size, $h3-line-height);
  font-family: $meta-normal-font-family;
}

.hero-section-action {
  @include adjust-font-size-to($small-font-size, 1);
}

// ----------------------------------------------------------------------------
// Hero Features
// ----------------------------------------------------------------------------

.hero-features {
  @include on-desktop {
    background: transparent url("../images/feature-icons/arrow.gif") 73.6842% 19px no-repeat;
  }
}

.hero-features-img {
  @include on-desktop {
    position: absolute;
    top: -6%;
    left: -6%;
    width: 112%;
  }

  img {
    display: block;
  }
}

// ----------------------------------------------------------------------------
// Hero Feature
// ----------------------------------------------------------------------------

.hero-feature {
  position: relative;
  display: block;
  color: $text-color;

  @include with-feature(generatedcontent) {
    padding-left: 84px;

    @include on-desktop {
      padding-top: 84px;
      padding-left: 0;
    }
  }

  h2,
  p {
    margin-bottom: 0;
  }

  &::before {
    @include at2x(url("../images/feature-icons/all.png"), url("../images/feature-icons/all@2x.png"), 190px);
    position: absolute;
    top: -3px;
    left: -3px;
    display: block;
    width: 60px;
    height: 60px;
    border: 3px solid $hero-feature-border-color;
    border-radius: 1000em;
    background-color: $hero-feature-background-color;
    content: " ";
    transition: all $out-duration ease-out;

    @include on-desktop {
      left: -12px;
    }
  }

  &:focus,
  &:hover {
    color: $text-color;

    &::before {
      transform: scale(1.25);
      transition-duration: $in-duration;
    }

    .hero-feature-action {
      color: $link-hover-color;
    }
  }
}

.find-hero-feature {
  &::before {
    background-position: 0 -65px;
  }

  &:focus,
  &:hover {
    &::before {
      background-position: 0 0;
    }
  }
}

.schedule-hero-feature {
  &::before {
    background-position: -65px -65px;
  }

  &:focus,
  &:hover {
    &::before {
      background-position: -65px 0;
    }
  }
}

.remember-hero-feature {
  &::before {
    background-position: -130px -65px;
  }

  &:focus,
  &:hover {
    &::before {
      background-position: -130px 0;
    }
  }
}

.hero-feature-action {
  color: $link-color;
}

// ----------------------------------------------------------------------------
// Hero Location Box
// ----------------------------------------------------------------------------

.hero-location-box {
  @include on-tablet-down {
    padding: 0;
    border: 0;
    background: transparent;
    box-shadow: none;
  }

  h2,
  p {
    margin: 0;
  }

  ul {
    @include on-tablet-up {
      column-count: 2;
    }
  }
}

.hero-location-box-list {
  margin-top: rhythm(1);

  li {
    @include ellipsis;
  }
}
