// ----------------------------------------------------------------------------
// Hacks
// ----------------------------------------------------------------------------

.provider-calendar__form {
  .patient-status-field .field-input {
    display: block;
  }
}

// ----------------------------------------------------------------------------
// Segment Controls
// ----------------------------------------------------------------------------

.segment-controls {
  margin-bottom: .75em;
  display: table;
  width: 100%;
}

.segment-controls__group-item {
  display: table-cell;
}

.segment-controls__label {
  position: relative;
  text-transform: uppercase;
  text-align: center;
  display: block;
  padding: 0 0 1.85em;
  font-size: .8em;

  span {
    background-color: $white;
    color: $muted-color;
    border: 1px solid $rule-color;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: .25em;
    left: 0;
    cursor: pointer;
    transition: background-color 500ms ease-out;

    &:hover {
      color: $white;
      background-color: $rule-color;
    }

    &:focus {
      border: 1px solid $charcoal;
      box-shadow: 0 none;
    }
  }
}

.segment-controls__button {
  display: none;

  &:checked + .segment-controls__label {

    span {
      color: $white;
      background-color: $blue;
      padding: .3em 0;
      border: 1px solid $blue;
      z-index: 10;
      border-radius: $input-border-radius;
      top: 0;
    }
  }
}

.segment-controls--block {
  .segment-controls,
  .segment-controls__group-item {
    display: block;
  }

  .segment-controls__label {
    padding: 0;

    span {
      position: relative;
      width: 95%;
      margin: auto;
    }
  }

  .segment-controls__button {
    &:checked + .segment-controls__label {
      span {
        width: 100%;
        padding: .1em 0;

        &::before {
          @include icon;
          @include icon-content("ok-sign");
          position: absolute;
          top: .2em;
          left: .5em;
          font-size: 1.2em;
        }
      }
    }
  }

  .segment-controls__group-item:not(:last-child) {
    .segment-controls__button:checked + .segment-controls__label {
      margin-bottom: -.45em;
    }
  }
}

// temporary hack to remove the checkmark from
// the segment-controls--block on the provider calendar form
// a better solution would be to include a proptype on the
// provider calendar form that adds a class of "no-check"
// to the segments if that property is passed to it

.provider-calendar__form {
  .segment-controls .segment-controls__button {
    &:checked + .segment-controls__label span {
      &::before {
        content: "" !important;
        display: none !important;
      }
    }
  }
}

@include on-tablet {
  .segment-controls--block {
    .segment-controls {
      display: table;
    }

    .segment-controls__group-item {
      display: table-cell;
    }

    .segment-controls__label {
      span {
        width: 100%;
      }
    }

    .segment-controls__button:checked + .segment-controls__label {
      span {
        top: .25em;
      }
    }
  }
}
