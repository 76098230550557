// ----------------------------------------------------------------------------
// Base Partner Styles
// ----------------------------------------------------------------------------

.lm-partner-header,
.lm-partner-footer {
  line-height: 0;
  padding: rhythm($space) 0;
}

.lm-partner-header__partner-logo,
.lm-partner-footer__partner-logo {
  float: left;
}

.lm-partner-header__partner-lm-logo,
.lm-partner-footer__partner-aptible-logo {
  float: right;
}

.lm-partner-header__partner-logo-img {
  height: 36px;
  max-height: 36px;
}

.lm-partner-footer {
  background: $white;
  padding: rhythm($space *2) 0;
}

.lm-partner-footer__partner-logo,
.lm-partner-footer__partner-aptible-logo {
  padding-top: 2.5em;
}

.lm-partner-footer__partner-aptible-logo {
  @extend .lm-text-right;
  line-height: 1em;
}

.lm-partner-footer__logo-img {
  margin-bottom: 1.5em;
}

@include on-tablet-down {
  .lm-partner-header__partner-logo,
  .lm-partner-footer__partner-logo,
  .lm-partner-header__partner-lm-logo,
  .lm-partner-footer__partner-aptible-logo {
    float: none;
    text-align: center;
  }

  .lm-partner-footer {
    height: auto;
  }

  .lm-partner-header__partner-lm-logo {
    display: none;
  }
}
