// ----------------------------------------------------------------------------
// LocalMed List Styles
// ----------------------------------------------------------------------------

.lm-two-column-list {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
  text-align: left;
  font-size: .9em;
  margin: 1.75em 0;
  width: 100%;

  li {
    display: inline-table;
    vertical-align: top;
    padding: .25em 1.75em .25em 0;
    width: 49.5%;
    box-sizing: border-box;
  }
}

@include on-mobile {
  .lm-two-column-list {
    display: block;

    li {
      display: block;
      width: 100%;
      padding-right: 0;
    }
  }
}
