// ----------------------------------------------------------------------------
// Form Variations
// ----------------------------------------------------------------------------

.form {
  &.is-submitting {
    position: relative;
    z-index: 1;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 105%;
      background: $form-is-submitting-background-color url("../images/loading.gif") center center no-repeat;
      content: " ";
    }
  }
}

.filter-form {
  .field {
    margin: 0;
    padding: rhythm(.25) rhythm($space);
    border-top: 1px solid $border-color;

    &:first-child {
      border-top: 0;
    }
  }

  .field-input[class*="icon-"] {
    &::before {
      left: 0;
      top: 0;
      text-align: left;
      color: $strong-color;
    }

    #{$all-text-inputs} {
      padding-left: rhythm($full-space, $input-font-size);
    }
  }

  &.right-icons-form {
    .field-input[class*="icon-"] {
      &::before {
        left: auto;
        right: 0;
        text-align: right;
      }

      #{$all-text-inputs} {
        padding-left: 0;
        padding-right: rhythm($full-space, $input-font-size);
      }
    }
  }

  label {
    color: $muted-color;
    text-transform: none;
    letter-spacing: 0;
  }

  #{$all-text-inputs},
  select,
  textarea {
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    border-width: 1px;
    border-style: solid none;
    border-color: transparent;
    background: transparent;

    &:focus {
      border-bottom-color: $blue;
    }
  }

  @include on-desktop {
    display: table;
    width: 100%;

    .field,
    .field-label,
    .field-input,
    .filter-form-cell {
      margin: 0;
      display: table-cell;
      vertical-align: top;
    }

    .filter-form-cell {
      .field,
      .field-label,
      .field-input {
        display: block;
      }
    }

    .field {
      padding: 0;
      border-top: 0;
      border-left: 1px solid $border-color;

      &:first-child {
        border-left: 0;
      }
    }

    .field-label,
    .field-input {
      padding: rhythm(.375) rhythm(.25);

      &:first-child {
        padding-left: rhythm($space);
      }

      &:last-child {
        padding-right: rhythm($space);
      }
    }

    .field-input[class*="icon-"] {
      &::before {
        top: rhythm(.375, $filter-form-icon-font-size, $offset: 1px);
        left: rhythm($space, $filter-form-icon-font-size);
      }
    }

    &.right-icons-form {
      .field-input[class*="icon-"] {
        &::before {
          left: auto;
          right: rhythm($space, $filter-form-icon-font-size);
        }
      }
    }

    #{$all-text-inputs},
    select,
    textarea {
      margin-top: rhythm(-.125, $input-font-size);
    }
  }
}

// ----------------------------------------------------------------------------
// Field Variations
// ----------------------------------------------------------------------------

.geolocated-field-input[class*="icon-"]::before {
  color: $blue;
}

.field-input.is-loading[class*="icon-"] {
  #{$all-text-inputs} {
    background-image: url("../images/loading-sm.gif");
    background-position: rhythm(.35) 50%;
    background-repeat: no-repeat;
  }

  &::before {
    display: none;
  }
}

.inline-input-field {
  #{$all-text-inputs},
  select {
    margin-bottom: 0;
  }

  &.error-field {
    #{$all-text-inputs},
    select {
      margin-bottom: rhythm(.5, $input-font-size);
    }
  }
}

.field label abbr {
  color: $red;
  font-weight: bold;
}

.hide-submit {
  visibility: hidden;
  position: absolute;
}

.hidden-if-disabled {
  :disabled,
  .is-disabled {
    display: none;
  }
}

// ----------------------------------------------------------------------------
// Input Variations
// ----------------------------------------------------------------------------

.field {
  @include on-tablet-up {
    .first-name-input,
    .last-name-input {
      width: column-width(3);
    }

    .first-name-input {
      margin-bottom: 0 !important;
    }

    .last-name-input {
      @include column-gutter;
    }
  }

  .month-input,
  .day-input,
  .year-input {
    @extend %text-center;
  }

  .month-input,
  .day-input {
    width: rhythm(2);
  }

  .year-input {
    width: rhythm(2.75);
  }

  .code-input {
    @extend %text-center;
    width: 6em;
  }
}

// ----------------------------------------------------------------------------
// Hacks
// ----------------------------------------------------------------------------

.field.no-space-bottom {
  margin-bottom: 0;
}

.password-checker ~ .error-message {
  display: none;
}

::placeholder {
  color: $muted-color;
}
