// ----------------------------------------------------------------------------
// Provider Calendar > Header
// ----------------------------------------------------------------------------

.provider-calendar__header {
  @extend %pie-clearfix;
}

// ----------------------------------------------------------------------------
// Provider Calendar > First Opening
// ----------------------------------------------------------------------------

.provider-calendar__first-opening {
  position: relative;
  margin-bottom: rhythm($space);

  a {
    transform: scale(1);
  }
}

// ----------------------------------------------------------------------------
// Provider Calendar > Date
// ----------------------------------------------------------------------------

.provider-calendar__date {
  position: relative;
  z-index: 35;
  perspective: 600px;

  a {
    color: $text-color;

    small {
      color: $link-color;
    }

    &:focus,
    &:focus small,
    &:hover,
    &:hover small {
      color: $link-hover-color;
    }
  }
}

// ----------------------------------------------------------------------------
// Provider Calendar > Datepicker
// ----------------------------------------------------------------------------

.provider-calendar__datepicker {
  @extend %small;
  border-radius: $dropdown-border-radius;
  box-shadow: 0 4px 12px rgba(#000, .2);
  position: absolute;
  top: 100%;
  left: rhythm(.5);
  margin-top: rhythm(.25);
  border: 1px solid $calendar-datepicker-border-color;
  background: $calendar-datepicker-background-color;

  &::after,
  &::before {
    position: absolute;
    bottom: 100%;
    left: 50%;
    width: 0;
    height: 0;
    border: solid transparent;
    content: " ";
    pointer-events: none;
  }

  &::after {
    margin-left: -5px;
    border-width: 5px;
    border-color: rgba($calendar-datepicker-background-color, 0);
    border-bottom-color: $calendar-datepicker-background-color;
  }

  &::before {
    margin-left: -6px;
    border-width: 6px;
    border-color: rgba($calendar-datepicker-border-color, 0);
    border-bottom-color: $calendar-datepicker-border-color;
  }
}

// ----------------------------------------------------------------------------
// Provider Calendar > Form
// ----------------------------------------------------------------------------

.provider-calendar__form {
  padding-bottom: rhythm(.5);
  border-top: 1px solid $border-color;
}

// ----------------------------------------------------------------------------
// Provider Calendar > Table
// ----------------------------------------------------------------------------

.provider-calendar__table {
  overflow-x: hidden;
}

// ----------------------------------------------------------------------------
// Provider Calendar (Boxed)
// ----------------------------------------------------------------------------

.provider-calendar--boxed {
  margin-bottom: rhythm(-$space);

  .provider-calendar__datepicker,
  .provider-calendar__form,
  .provider-calendar__table {
    margin: 0 rhythm(-$space);
  }

  .openings-calendar {
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
  }
}

// ----------------------------------------------------------------------------
// Responsive States
// ----------------------------------------------------------------------------

@include min-screen($calendar-header-breakpoint) {
  .provider-calendar__title {
    float: left;
  }

  .provider-calendar__first-opening {
    float: right;
    margin-top: rhythm(.5);
  }
}

@include on-desktop {
  .provider-calendar__form {
    padding-bottom: 0;
  }

  .provider-calendar {
    .patient-status-field {
      width: rhythm(10);
    }

    .reason-for-visit-field {
      .field-input {
        width: rhythm(8);
      }
    }
  }
}
