// ----------------------------------------------------------------------------
// LocalMed Spacing Utility Classes
// ----------------------------------------------------------------------------

.lm-no-bottom-margin {
  margin-bottom: 0;
}

.lm-no-margin {
  margin: 0;
}

.lm-bottom-margin {
  margin-bottom: rhythm($space);
}

.lm-top-margin {
  margin-top: rhythm($space);
}

.lm-top-bottom-margin {
  margin: rhythm($space) 0;
}

.lm-right-margin {
  @extend %space-right;
}

.lm-no-padding {
  padding: 0;
}

.lm-no-right-padding {
  padding-right: 0;
}

.lm-no-left-padding {
  padding-left: 0;
}

.lm-no-bottom-padding {
  padding-bottom: 0;
}

.lm-no-top-padding {
  padding-top: 0;
}

.lm-right-padding {
  padding-right: rhythm($space);
}

.lm-left-padding {
  padding-left: rhythm($space);
}

.lm-bottom-padding {
  padding-bottom: rhythm($space);
}

.lm-top-padding {
  padding-top: rhythm($space);
}
