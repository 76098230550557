// ----------------------------------------------------------------------------
// Maps
// ----------------------------------------------------------------------------

.map {
  height: rhythm(10);
  position: relative;
}

.map--bordered {
  border: 1px solid $box-border-color;
}

.map--full {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// ----------------------------------------------------------------------------
// Markers
// ----------------------------------------------------------------------------

.marker {
  @include at2x(url("../images/markers.png"), url("../images/markers@2x.png"), $marker-sprite-width);
  display: block;
  width: $marker-width;
  height: $marker-height;
  background-position: -59px 0;
  background-repeat: no-repeat;
  color: $marker-text-color;
  text-align: center;
  font-weight: bold !important;
  font-size: $marker-font-size;
  font-family: $base-font-family;
  line-height: rhythm($marker-line-height, $marker-font-size);

  .icon-localmed {
    font-size: 1.25em;
    line-height: 1.25;
  }
}

%marker--active {
  width: $active-marker-width;
  height: $active-marker-height;
  font-size: $active-marker-font-size;
  line-height: rhythm($active-marker-line-height, $active-marker-font-size);
  background-position: 0 0;
  color: $marker-text-color;
}

.marker--active {
  @extend %marker--active;
}

a .marker,
a.marker {
  background-position: -32px 0;
}

a:focus .marker,
a:hover .marker,
a.marker:focus,
a.marker:hover {
  @extend %marker--active;
}

a .marker--active,
a.marker--active {
  background-position: 0 0;
}

// ----------------------------------------------------------------------------
// Marker Media
// ----------------------------------------------------------------------------

.marker-media {
  .media-obj {
    @extend %space-right;
    width: $active-marker-width;
  }

  .marker {
    margin-top: 10px;
    margin-left: $active-marker-width / 2 - $marker-width / 2;
  }

  .marker--active {
    margin-top: 5px;
    margin-left: 0;
  }

  .ellipsis {
    display: block;
  }
}

a.marker-media {
  &:focus,
  &:hover {
    .marker {
      margin-top: 5px;
      margin-left: 0;
    }
  }
}

.marker-media--tall {
  .media-obj {
    padding-top: rhythm(.5);
    padding-right: rhythm(.5);
  }
}

// ----------------------------------------------------------------------------
// Cluster
// ----------------------------------------------------------------------------

@mixin cluster-size($size, $border-size: 4px) {
  width: $size;
  height: $size;
  line-height: $size;

  &::after {
    top: -$border-size;
    left: -$border-size;
    width: $size + $border-size * 2;
    height: $size + $border-size * 2;
  }
}

.cluster {
  @include cluster-size(20px);
  display: block;
  position: relative;
  border-radius: 1000em;
  box-shadow: 0 0 4px $cluster-box-shadow-color;
  background-color: $cluster-background-color;
  color: $cluster-text-color;
  text-align: center;

  &::after {
    position: absolute;
    z-index: -1;
    display: block;
    border-radius: 1000em;
    background-color: $cluster-border-color;
    content: " ";
  }
}

%cluster--active {
  background: $active-cluster-background-color;
  z-index: 500 !important;

  &::after {
    background-color: $active-cluster-border-color;
  }
}

.cluster--active {
  @extend %cluster--active;
}

a.cluster {
  &:focus,
  &:hover {
    @extend %cluster--active;
  }
}

.cluster--small {
  @include cluster-size(10px, $border-size: 3px);
}

.cluster--large {
  @include cluster-size(30px)
}
