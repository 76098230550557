$marketing-header-logo-small-width: 109px;
$marketing-header-logo-large-width: 140px;
$marketing-header-toggle-size: 20px;
$marketing-header-item-small-font-size: 13px;
$marketing-header-item-large-font-size: 16px;
$marketing-header-dropdown-font-size: 13px;
$marketing-header-dropdown-triangle-size: 6px;
$maerkting-header-hover-background-color: #f2f2f2;

.marketing-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
  padding: 0 em-rhythm(.25);
  background: $white;
  position: relative;

  @include on-desktop {
    padding: 0 em-rhythm(.5);
  }
}

.marketing-header__mobile-nav {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;

  @include on-desktop {
    display: none;
  }
}

.marketing-header__nav {
  display: none;

  @include on-desktop {
    display: flex;
    flex: 1;
  }
}

.marketing-header__items {
  margin: 0;
  padding: 0;

  @include on-desktop {
    display: flex;
    align-items: center;
  }
}

.marketing-header__items--main {
  flex: 1;
}

// ----------------------------------------------------------------------------
// Button
// ----------------------------------------------------------------------------

.marketing-header__button {
  &.lm-button {
    font-size: em($marketing-header-item-small-font-size);
    margin-left: em-rhythm(.25, $marketing-header-item-small-font-size);
    margin-right: em-rhythm(.25, $marketing-header-item-small-font-size);
    border: 0;

    @include on-desktop {
      font-size: em($marketing-header-item-large-font-size);
      margin-left: em-rhythm(.5, $marketing-header-item-large-font-size);
      margin-right: em-rhythm(.5, $marketing-header-item-large-font-size);
    }
  }

  &.lm-button, &.lm-button:last-child {
    margin-bottom: 0;
  }
}

// ----------------------------------------------------------------------------
// Item
// ----------------------------------------------------------------------------

.marketing-header__item {
  display: block;
  font-family: $meta-normal-font-family;
  font-size: em($marketing-header-item-small-font-size);
  line-height: em-rhythm(1, $marketing-header-item-small-font-size);
  background: transparent;
  color: $black;
  border: 0;
  white-space: nowrap;
  transition: all 200ms ease-out;

  @include on-tablet-down {
    font-size: em($marketing-header-item-small-font-size);
    line-height: em-rhythm(1, $marketing-header-item-small-font-size);
    padding: em-rhythm(.625, $marketing-header-item-small-font-size)
      em-rhythm(.25, $marketing-header-item-small-font-size);

    .marketing-header__dropdown & {
      font-size: em($marketing-header-item-large-font-size);
      line-height: em-rhythm(1, $marketing-header-item-large-font-size);
      padding: em-rhythm(.25, $marketing-header-item-large-font-size)
        em-rhythm(1, $marketing-header-item-large-font-size)
        em-rhythm(.25, $marketing-header-item-large-font-size)
        em-rhythm(.5, $marketing-header-item-large-font-size);

      &:hover {
        padding-left: em-rhythm(.75, $marketing-header-item-large-font-size);
        padding-right: em-rhythm(.75, $marketing-header-item-large-font-size);
      }
    }
  }

  @include on-desktop {
    font-size: em($marketing-header-item-large-font-size);
    line-height: em-rhythm(1, $marketing-header-item-large-font-size);
    padding: em-rhythm(.675, $marketing-header-item-large-font-size)
      em-rhythm(.5, $marketing-header-item-large-font-size);

    .marketing-header__dropdown & {
      font-size: em($marketing-header-dropdown-font-size);
      padding: em-rhythm(.25, $marketing-header-dropdown-font-size)
        em-rhythm(1, $marketing-header-dropdown-font-size)
        em-rhythm(.25, $marketing-header-dropdown-font-size)
        em-rhythm(.5, $marketing-header-dropdown-font-size);

      &:hover {
        padding-left: em-rhythm(.75, $marketing-header-dropdown-font-size);
        padding-right: em-rhythm(.75, $marketing-header-dropdown-font-size);
      }
    }
  }

  &:hover {
    background: $maerkting-header-hover-background-color;
    color: $blue;
  }

  &[aria-haspopup="true"] {
    &:hover {
      background: transparent;
      color: $muted-color;
    }
  }

  &[aria-expanded="true"] {
    color: $muted-color;

    .marketing-header__caret {
      transform: rotate(180deg);
    }
  }
}

.marketing-header__caret {
  opacity: .5;
  transition: transform 150ms ease-out;

  [aria-expanded="true"] & {
    transform: rotate(180deg);
  }
}

.marketing-header__menu-header {
  font-family: $meta-normal-font-family;
  font-size: em($marketing-header-item-small-font-size);
  line-height: em-rhythm(1, $marketing-header-item-small-font-size);
  padding: em-rhythm(.25, $marketing-header-item-small-font-size)
    em-rhythm(.5, $marketing-header-item-small-font-size);
  text-transform: uppercase;
  letter-spacing: .1em;
  color: $muted-color;
}

.marketing-header__divider {
  height: 1px;
  overflow: hidden;
  margin: em-rhythm(.25) 0;
  background: $border-color;
}

// ----------------------------------------------------------------------------
// Dropdown
// ----------------------------------------------------------------------------

.marketing-header__dropdown-container {
  position: relative;

  .marketing-header__mobile-nav & {
    position: static;
  }
}

.marketing-header__dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: em-rhythm(-.25);
  min-width: em-rhythm(5);
  background: $white;
  border-radius: $border-radius;
  z-index: $dropdown-z-index;
  padding: em-rhythm(.25) 0;
  box-shadow: rgba(0, 0, 0, .19) 0 10px 30px 0, rgba(0, 0, 0, .23) 0 6px 10px 0;
  border: 1px solid $border-color;
  transition: opacity 100ms ease-out, transform 200ms ease-out;

  &::before, &::after {
    width: 0;
    height: 0;
    border-width: $marketing-header-dropdown-triangle-size;
    border-style: solid;
    content: "";
    border-color: transparent transparent currentColor;
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -$marketing-header-dropdown-triangle-size;
  }

  &::before {
    color: $border-color;
    z-index: $dropdown-z-index - 1;
  }

  &::after {
    color: $white;
    z-index: $dropdown-z-index + 1;
    margin-bottom: -1px;
  }

  &[aria-hidden="true"] {
    pointer-events: none;
    opacity: 0;
    transform: translateY(em-rhythm(-.25));
  }

  &[aria-hidden="false"] {
    pointer-events: auto;
    opacity: 1;
    transform: translateY(0);
  }

  .marketing-header__items--main & {
    left: 0;
    right: auto;
  }

  .marketing-header__mobile-nav & {
    width: 100%;

    &::before, &::after {
      left: auto;
      right: em-rhythm(.5);
      margin-left: 0;
      margin-right: $marketing-header-dropdown-triangle-size / 2;
    }
  }
}

// ----------------------------------------------------------------------------
// Logo
// ----------------------------------------------------------------------------

.marketing-header__logo {
  flex: 0 0 $marketing-header-logo-small-width;
  padding: 0 em-rhythm(.5);

  img {
    display: block;
  }

  @include on-desktop {
    flex: 0 0 $marketing-header-logo-large-width;
  }
}

// ----------------------------------------------------------------------------
// Toggle
// ----------------------------------------------------------------------------

.marketing-header__toggle {
  background: transparent;
  border: 0;
  box-sizing: content-box;
  width: $marketing-header-toggle-size;
  height: $marketing-header-toggle-size;
  position: relative;
}

.marketing-header__toggle-line {
  width: $marketing-header-toggle-size;
  height: 2px;
  overflow: hidden;
  display: block;
  background: $strong-color;
  transition: all 200ms ease-out;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -1px;
  margin-left: $marketing-header-toggle-size / -2;
}

.marketing-header__toggle-line--top {
  transform: translateY(-7px);

  [aria-expanded="true"] & {
    transform: rotate(135deg);
  }
}

.marketing-header__toggle-line--middle {
  [aria-expanded="true"] & {
    opacity: 0;
    transform: rotate(135deg);
  }
}

.marketing-header__toggle-line--bottom {
  transition-delay: 50ms;
  transform: translateY(7px);

  [aria-expanded="true"] & {
    transform: rotate(45deg);
  }
}
