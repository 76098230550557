.blue-background {
  .site-wrapper {
    background-color: $blue;
    background-image: linear-gradient(to bottom, lighten($blue, 7%) 50%, darken($blue, 7%) 100%);
  }
}

.content-intro {
  @extend %container !optional;
  background-color: $background-color;
}
