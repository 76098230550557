// ----------------------------------------------------------------------------
// Listing
// The primary view of the Providers in the search results.
// ----------------------------------------------------------------------------

.listing {
  @extend %pie-clearfix;
}

.listing__photo {
  @extend %left;
  margin-right: rhythm(.5);
  margin-bottom: rhythm(.5);
  width: $listing-small-photo-size;

  img {
    display: block;
  }
}

.listing__name {
  @include adjust-font-size-to($h3-font-size, $h3-line-height);
  // This vertically centers the name to the photo.
  padding-top: rhythm(.25, $h3-font-size);
}

.listing__info,
.listing__primary-info {
  clear: left;
}

.listing__primary-info,
.listing__secondary-info {
  @include adjust-font-size-to($small-font-size, $small-line-height);
}

.listing__primary-info {
  margin-bottom: 0;
}

.listing__openings {
  margin-top: rhythm($space);
}

.listing__next-opening {
  margin-bottom: rhythm(.25);
}

.listing__info--small {
  dt,
  dd {
    @include adjust-font-size-to($small-font-size, $small-line-height);
  }

  dt {
    @include ellipsis;
    float: left;
    clear: left;
    overflow: hidden;
    width: rhythm(3.75, $small-font-size);
  }

  dd {
    @include spacing(.25, $font-size: $small-font-size);
    margin-left: rhythm(4, $small-font-size);
  }
}

// ----------------------------------------------------------------------------
// On Tablet
// ----------------------------------------------------------------------------

@include on-tablet-up($columns: 16) {
  %listing-tablet-column-base {
    @include column-base;
  }

  %listing-tablet-column-gutter {
    @include column-gutter;
  }

  .listing {
    position: relative;

    // Horizontal Dividing Line
    &::before {
      position: absolute;
      top: rhythm(-.5);
      left: column-distance(9, $with-gutter: false) + column-gutter() / 2;
      display: block;
      padding-top: rhythm(1);
      width: 1px;
      height: 100%;
      border-left: 1px dashed $border-color;
      content: " ";
      cursor: default;
      pointer-events: none;
    }
  }

  .listing__photo {
    @extend %listing-tablet-column-base;
    width: column-width(3);
    margin-right: 0;
    margin-bottom: 0;
  }

  .listing__profile {
    @extend %listing-tablet-column-base;
    @extend %listing-tablet-column-gutter;
    width: column-width(6);
  }

  .listing__name {
    padding-top: 0;
  }

  .listing__primary-info,
  .listing__info--small {
    &,
    &:last-child {
      margin-bottom: 0;
    }
  }

  .listing__openings {
    @extend %listing-tablet-column-base;
    @extend %listing-tablet-column-gutter;
    width: column-width(7);
    margin-top: 0;
    text-align: center;
  }
}

@include on-tablet-down {
  // In order to keep the doctor details inline we must
  // allow white space to wrap and manually set up vertical rhythm
  .listing__info li {
    line-height: 1em;
    margin-bottom: .75em;

    &.ellipsis {
      white-space: normal;
    }

    // position relative to hack the viewport into allowing rhythm
    &:first-child {
      position: relative;
      top: .25em;
      margin-bottom: 1em;
    }
  }
}

// ----------------------------------------------------------------------------
// On Desktop
// ----------------------------------------------------------------------------

// Reuse this bit because the breakpoint changes in the Widget version.
@import "./listing-on-desktop";
