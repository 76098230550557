.fav-button {
  @include adjust-font-size-to($small-font-size, .75);
  @include inline-block;
  position: absolute;
  bottom: -3px;
  left: -5px;
  padding: relative-font-size(3px) relative-font-size(5px);
  background: $fav-button-background-color;
  border-top-right-radius: $small-border-radius;

  .fav-button-message {
    @include adjust-font-size-to($small-font-size);
    display: none;
    margin-left: relative-font-size(5px);
    color: $muted-color;
  }

  .icon-star::before {
    margin-right: 0;
    margin-left: 0;
    color: $border-color;
  }

  &.is-faved {
    .icon-star::before {
      color: $red;
    }
  }

  &:focus,
  &:hover {
    .icon-star::before {
      color: $link-color;
    }
  }

  .profile & {
    @include adjust-font-size-to($base-font-size);
    bottom: 4px;
    left: 0;
    padding: relative-font-size(3px) relative-font-size(5px) relative-font-size(3px) 0;
    height: $base-line-height;

    .icon-star::before {
      margin-left: relative-font-size(8px);
    }

    &:focus,
    &:hover {
      border-radius: 0;
      padding-right: 0;
      width: 100%;
      border-right: 0;

      .fav-button-message {
        display: inline;
      }
    }
  }
}
