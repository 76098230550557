
// ----------------------------------------------------------------------------
// LocalMed Borders
// ----------------------------------------------------------------------------

.lm-border-left {
  border-left: 1px solid $border-color;
  padding-left: rhythm($space) * 1.5;
}

.lm-border-right {
  border-right: 1px solid $border-color;
  padding-right: rhythm($space) * 1.5;
}

.lm-border-top {
  border-top: 1px solid $border-color;
  padding-top: rhythm($space) * 1.5;
}

.lm-border-bottom {
  border-bottom: 1px solid $border-color;
  padding-bottom: rhythm($space) * 1.5;
}

.lm-border {
  border: 1px solid $border-color;
  padding: rhythm($space) * 1.5;
}
