// Fixes crux spacing issue

.lm-crux-field-input {
  input[type="text"] {
    padding-top: 0.1em;
    font-style: normal;
    font-family: $base-font-family;
  }

  label {
    left: 0;
  }

  @include max-screen($s-screen) {
    // this will remove the auto zoom default behavior
    // on mobile browsers.
    // http://stackoverflow.com/a/18949718/1327678
    input[type="text"],
    select {
      font-size: 16px;
    }
  }
}

.lm-crux-field-input--with-icon[class*="icon-"] {
  &::before {
    font-size: 1.25em;
    height: 35px;
    line-height: 35px;
    padding-top: 0;
  }

  &::after {
    display: none;
  }
}
