.lm-crux-home {
  @include square(100%);

  background: $white;
  overflow-x: hidden;
  position: relative;

  .lm-hero-features-container {
    position: relative;
    z-index: 13;
  }

  @include on-tablet-down {
    .location-container {
      background: $slight-white;
      position: relative;
      z-index: 10;

      &::before {
        @include square(100%);

        content: "";
        background: $slight-white;
        left: 0;
        position: absolute;
        transform: skewY(-4deg);
        top: -1em;
      }
    }
  }
}

// Crux Home Hero

.lm-crux-home-hero {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  // height and min-height is here to fix IE issues with flexbox vertical layouts
  // http://stackoverflow.com/questions/19371626/flexbox-not-centering-vertically-in-ie/25386261#25386261
  height: 450px;

  // margin set to avoid conflict with fixed header nav fixed position
  // margin-top: rhythm($crux-global-spacing * 1.5);
  min-height: 450px;
  padding: rhythm($crux-global-spacing) rhythm($crux-global-spacing)
    rhythm($crux-global-spacing * 2);

  h1 {
    color: $white;
    text-align: center;
    text-shadow: 0 0 0.5em $black;
    font-size: 3rem;
  }

  .lm-button {
    border-radius: 0 ($border-radius * 2) ($border-radius * 2) 0;
    transform-origin: 0 50%;

    // to make the button appear fixed to the rest of the row
    margin-left: 6px;
  }

  @include min-screen($xl-screen) {
    height: 650px;
    min-height: 650px;
  }

  @include on-tablet-down {
    .lm-crux-field-input.lm-crux-home-hero__specialty {
      input {
        border-radius: $border-radius $border-radius 0 0;
      }
    }

    h1 {
      font-size: 1.5em;
      line-height: 1;
    }

    .lm-button {
      border-radius: $border-radius * 2;
    }

    .lm-h-flex-auto:not(:last-child) {
      margin-bottom: 1.25em;
    }
  }

  @include max-screen($m-screen) {
    // margin-top: rhythm($crux-global-spacing);

    .lm-button {
      margin-left: 2px;
    }
  }

  @include max-screen($s-screen) {
    height: 100%;
    min-height: 360px;
    padding-bottom: 2em;
  }
}

.lm-crux-home-hero__form {
  [class^="lm-h-flex"] {
    margin-bottom: 0;
  }

  // fixes issue on legacy mobile phones that do no honor
  // flex properties properly. only happens within this form
  @include max-screen($s-screen) {
    display: block;
    width: 100%;

    .lm-crux-home-hero__form-input-container {
      display: block;
      width: 100%;
    }
  }
}

// the hero wrapper
.lm-crux-hero {
  background: $slight-white;
}

// these styles manage all our background sizes
// and changes the image based on a random chance.
// can eventually be used to target imagery based on
// user location.

.lm-crux-home-hero--a {
  @include crux-at2x("../images/Desktop-LocalMed-Home-Image-A-Large", "jpg", cover);

  @include max-screen($s-screen) {
    @include crux-at2x("../images/Mobile-Portrait-LocalMed-Home-Image-A", "jpg", cover);
  }
}

.lm-crux-home-hero--b {
  @include crux-at2x("../images/Desktop-LocalMed-Home-Image-B-Large", "jpg", cover);

  @include max-screen($s-screen) {
    @include crux-at2x("../images/Mobile-Portrait-LocalMed-Home-Image-B", "jpg", cover);
  }
}

.lm-home-dentists-pitch {
  @include on-tablet-down {
    padding: rhythm($crux-global-spacing);

    .lm-spacer {
      display: none;
      width: 0;
      margin: 0;
      padding: 0;
    }
  }

  @include max-screen($s-screen) {
    padding-top: 0;
  }

  padding: rhythm($crux-global-spacing * 2.5) rhythm($crux-global-spacing);
  position: relative;
  color: $crux-text-gray;
  font-family: $meta-normal-font-family;
  line-height: rhythm($crux-global-spacing);

  h1,
  h2 {
    color: $crux-text-gray;
  }

  h1 {
    font-size: 3em;
    margin-bottom: 0;
  }

  h2 {
    font-family: $meta-normal-font-family;
  }

  p {
    margin-bottom: rhythm($crux-global-spacing);
  }

  &::after {
    @include square(100%);

    background: $white url("../images/dentist-pitch-bg.png") no-repeat top right;
    content: "";
    left: 0;
    position: absolute;
    top: -1.5em;
    z-index: 1;
    transform: skewY(-2deg);

    @include on-tablet-down {
      background: $white;
      background-image: none;
      transform: skewY(2deg);
    }
  }

  div {
    position: relative;
    z-index: 2;
  }

  img {
    width: 100%;

    @include max-screen($s-screen) {
      display: none;
    }
  }
}

.lm-crux-field-input--home-hero {
  input[type="text"] {
    border-right: 0;
  }

  select {
    max-width: 100%;
  }

  @include max-screen($s-screen) {
    input[type="text"] {
      border-right: 1px solid $border-color;
      border-bottom: 0;

      &:focus {
        border-bottom: 1px solid $blue;
      }
    }
  }
}

.lm-crux-home-hero__input-skeleton {
  background: $white;
  height: 37px;
}
