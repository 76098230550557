// ----------------------------------------------------------------------------
// Base
// ----------------------------------------------------------------------------

.searchbar {
  @extend %blue-container !optional;
  padding-top: rhythm($space);
  padding-bottom: rhythm($space);

  @include on-tablet-up {
    padding-top: rhythm($full-space);
    padding-bottom: rhythm($full-space);
  }

  a {
    color: $searchbar-link-color;
  }

  .field {
    margin-bottom: 0;
  }

  .field-label {
    label {
      color: $inverse-text-color;
      text-transform: none;
      white-space: nowrap;
      letter-spacing: 0;
    }
  }

  #{$all-text-inputs} {
    box-shadow: none;
    background: $searchbar-input-background-color;
    border-color: $searchbar-background-color;
  }

  #{$all-text-inputs} {
    &:focus {
      border-color: $charcoal;
    }
  }

  .tt-dropdown-menu {
    border-color: $blue;
  }

  .tt-header {
    @extend %muted;
  }

  .media-obj {
    margin-left: rhythm($space);
  }
}

// ----------------------------------------------------------------------------
// Toggle
// ----------------------------------------------------------------------------

.searchbar-search {
  .field-label {
    display: none;
  }
}

.searchbar-toggle {
  @extend %icon-replace;
  @include adjust-font-size-to($h3-font-size, 1.5);
}

// ----------------------------------------------------------------------------
// Advanced
// ----------------------------------------------------------------------------

.searchbar-advanced {
  display: none;

  .field {
    @include spacing;
  }
}

// ----------------------------------------------------------------------------
// Active State
// ----------------------------------------------------------------------------

.searchbar.is-active {
  .searchbar-advanced {
    display: block;
  }

  .icon-plus-sign::before {
    @include icon-content("minus-sign");
  }
}
