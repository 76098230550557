.dashbar {
  h1 {
    padding-top: rhythm(.375, $h1-font-size);
  }
}

.dashbar-column-left {
  padding-top: rhythm(.5);
  padding-bottom: rhythm(.5);
}

.dashbar-column-right {
  display: none;
}

@include on-tablet-up($header-breakpoint) {
  %dashbar-tablet-column-base {
    @include column-base;
    width: 50%;
  }

  .dashbar-column-left {
    @extend %dashbar-tablet-column-base;
    padding-top: rhythm(.125);
    padding-bottom: rhythm(.125);
  }

  .dashbar-column-right {
    @extend %dashbar-tablet-column-base;
  }
}

@include on-desktop {
  .dashbar-column-left {
    width: 45%;
    padding-top: rhythm(.5);
    padding-bottom: rhythm(.5);
  }

  .dashbar-column-right {
    width: 55%;
  }
}
