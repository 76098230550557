$calendar-table-mobile-header-date-font-size: 10px;
$calendar-table-mobile-opening-font-size: 12px;

.calendar-table {
  border-bottom: 1px solid $border-color;

  thead th .muted {
    white-space: nowrap;

    @include on-mobile {
      @include adjust-font-size-to($calendar-table-mobile-header-date-font-size, .75, $small-font-size);
    }
  }
}

.calendar-table__opening {
  @include on-mobile {
    @include adjust-font-size-to($calendar-table-mobile-opening-font-size, $calendar-opening-line-height);
  }
}
