// ----------------------------------------------------------------------------
// LocalMed Buttons v2
// ----------------------------------------------------------------------------

$lm-button-text: $white;

@mixin lm-button-mixin($text-color, $color) {
  color: $text-color;
  background: $color;
  border: 1px solid $color;
  border-bottom: 3px solid darken($color, 15%);
  margin-right: .5em;

  &:last-child {
    margin-right: 0;
  }

  &::-moz-focus-inner {
    border: 0 !important;
  }

  &:hover {
    color: $text-color;
    background: lighten($color, 8%);
    outline: 0 !important;
  }

  &:focus,
  &:active {
    color: $text-color;
    border: 1px solid $color;
    background: darken($color, 5%);
    border-bottom: 3px solid darken($color, 5%);
    box-shadow: 0 .15em .15em darken($color, 22%) inset;
    outline: 0 !important;
    transform: translateY(0) scaleY(.95) scaleX(.95);
  }

  &:disabled,
  &.is-disabled {
    background: lighten(desaturate($color, 25%), 17%);
    transition: none 0ms;
    cursor: default;
    border-bottom: 3px solid darken(desaturate($color, 25%), 5%);

    &:focus,
    &:active {
      box-shadow: 0 0 0;
      transform: none;
    }
  }
}

@mixin lm-button-mixin-outline($text-color, $color) {
  color: $text-color;
  background: $color;
  border: 1px solid $text-color;
  border-bottom: 3px solid darken($text-color, 15%);
  margin-right: .5em;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: $text-color;
    background: darken($color, 5%);
    box-shadow: 0 .25em .05em darken($text-color, 15%);
  }

  &:focus,
  &:active {
    color: $text-color;
    border: 1px solid $text-color;
    background: darken($color, 2.5%);
    border-bottom: 3px solid darken($text-color, 5%);
    box-shadow: 0 .15em .15em darken($text-color, 22%) inset;
    outline: none;
  }
}

%lm-button-base {
  display: inline-block;
  padding: .7em 1.25em .55em;
  font-family: $meta-normal-font-family;
  line-height: 1em;
  text-align: center;
  border-radius: $border-radius * 2;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  transition: all 100ms ease-in-out, transform 10ms ease, background 75ms ease-in-out;
  margin-bottom: 1em;

  > [class*="icon"] {
    font-size: inherit;
    display: inline-block;
    margin-left: .25em;
  }
}

.lm-button {
  @extend %lm-button-base;
  @include lm-button-mixin($lm-button-text, $charcoal-gray);
}

.lm-button--primary,
.button.lm-button--primary,
.lm-button--red,
.button.lm-button--red {
  @extend %lm-button-base;
  @include lm-button-mixin($lm-button-text, $red);
}

.lm-button--secondary,
.button.lm-button--secondary,
.lm-button--blue,
.button.lm-button--blue {
  @extend %lm-button-base;
  @include lm-button-mixin($lm-button-text, $blue);
}

.lm-button--outline-red {
  @extend %lm-button-base;
  @include lm-button-mixin-outline($red, $lm-button-text);
}

.lm-button--success {
  @extend %lm-button-base;
  @include lm-button-mixin($lm-button-text, $green);
}

.lm-button--cancel {
  display: inline-block;
}

.lm-button--icon-only {
  > [class*="icon"] {
    display: inline-block;
    margin-left: 0;
  }
}

// Square Buttons

%lm-button-square-base {
  border-radius: 0;
  border: 0 none;
  padding: .75em 1em .5em;
  border-bottom: .25em solid;

  &:focus,
  &:active {
    transform: scaleY(.9) scaleX(.9);
  }
}

.lm-button--copy {
  @extend %lm-button-square-base;
  @include adjust-font-size-to($small-font-size);
  cursor: copy;
  font-weight: bold;
  background: $blue;
  border-bottom: .25em $blue-shadow solid;

  &:hover {
    background: saturate($blue-tinted-dark, 20%);
  }

  &:focus,
  &:active {
    box-shadow: 0 .15em .15em darken($blue, 22%) inset;
    border-bottom: .25em $blue-shadow solid;
  }

  &.success {
    background: $green;
    border-bottom: .25em $green-shadow solid;
  }
}
