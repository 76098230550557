// To Bring Into Crux

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  85% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(.05);
    opacity: 0;
  }
}

@mixin fade-out {
  animation-delay: 2s;
  animation-duration: .5s;
  animation-name: fadeOut;
  animation-iteration-count: 1;
}

.lm-crux-alert-box--fade-out {
  @include fade-out;

  animation-fill-mode: forwards;
  opacity: 1;
  position: fixed;
  right: 0;
  top: 60px;
}
