$echosign-width: 772px + $base-line-height * 2;

.echosign-reveal-modal {
  width: $echosign-width;
  margin-left: $echosign-width / -2;

  @include max-screen($echosign-width) {
    left: 0;
    margin-left: 0;
  }
}
