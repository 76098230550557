.flexslider {
  position: relative;
  .slides {
    @extend %no-bullets;
    @extend %pie-clearfix;
    li {
      // Hide the slides before the JS is loaded. Avoids image jumping
      display: none;
      margin-right: 6px;
      -webkit-backface-visibility: hidden;
    }
    img {
      display: block;
      width: 100%;
    }
  }
}

// No JavaScript Fallback
.no-js .slides > li:first-child {
  display: block;
}

.flex-viewport {
  max-height: 2000px;
  transition: all 1s ease;
  .loading & {
    max-height: 300px;
  }
}

.flex-direction-nav {
  @extend %no-bullets;
  *height: 0;
  a {
    position: absolute;
    top: 0;
    z-index: 10;
    display: block;
    overflow: hidden;
    width: rhythm($full-space);
    height: 100%;
    outline: none;
    background: $background-color;
    box-shadow: 0 0 10px rgba(black, .7);
    color: $link-color;
    text-decoration: none;
    cursor: pointer;
    &::before {
      @extend %icon;
      display: inline-block;
      width: rhythm($full-space, 20px);
      text-align: center;
      font-size: 20px;
      line-height: 100px;
    }
    &:focus,
    &:hover {
      background: $link-color;
      color: white;
    }
  }
  .flex-prev {
    left: rhythm(-$full-space);
    &::before {
      @include icon-content("angle-left");
    }
  }
  .flex-next {
    right: rhythm(-$full-space);
    &::before {
      @include icon-content("angle-right");
    }
  }
}
