// ----------------------------------------------------------------------------
// LocalMed Display Utility Classes
// ----------------------------------------------------------------------------

.lm-inline-block {
  display: inline-block;
}

.lm-block {
  display: block;
}
