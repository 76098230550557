// ----------------------------------------------------------------------------
// Fade
// ----------------------------------------------------------------------------

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-enter {
  animation: fade 300ms ease-out;
}

.fade-leave {
  animation: fade 300ms ease-out reverse;
}

// ----------------------------------------------------------------------------
// Fade In
// ----------------------------------------------------------------------------

.fadein-enter {
  animation: fade 300ms ease-out;
}

.fadein-leave {
  display: none;
  opacity: 0;
}

// ----------------------------------------------------------------------------
// Fade Out
// ----------------------------------------------------------------------------

.fadeout-enter {
  opacity: 1;
}

.fadeout-leave {
  animation: fade 300ms ease-out reverse;
}

// ----------------------------------------------------------------------------
// Flip Down
// ----------------------------------------------------------------------------

@keyframes flipdown {
  0% {
    transform: rotateX(-60deg);
    opacity: 0;
  }

  100% {
    transform: rotateX(0);
    opacity: 1;
  }
}

%flipdown-base {
  transform-style: preserve-3d;
  transform-origin: 50% 0;
}

.flipdown-enter {
  @extend %flipdown-base;
  animation: flipdown 300ms ease-out;
}

.flipdown-leave {
  @extend %flipdown-base;
  animation: flipdown 300ms ease-out reverse;
}

// ----------------------------------------------------------------------------
// Scaleup
// ----------------------------------------------------------------------------

@keyframes scaleupenter {
  0% {
    transform: scale(.8);
    opacity: 0;
  }

  70% {
    transform: scale(1.1);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scaleupleave {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.scaleup-enter {
  animation: scaleupenter 300ms ease-out;
}

.scaleup-leave {
  animation: scaleupleave 300ms ease-out;
  position: absolute;
  top: 0;
  left: 0;
}

// ----------------------------------------------------------------------------
// Growheight
// ----------------------------------------------------------------------------

@keyframes growheight {
  to {
    max-height: rhythm(20);
  }
}
