.toggle-fieldset {
  display: none;

  @include with-feature(cssanimations) {
    overflow: hidden;
    max-height: 0;
    // Fixes: bottom field border doesn't show after animation
    padding-bottom: 1px;

    &.is-active {
      animation: growheight .5s ease forwards;
    }
  }

  &.is-active {
    display: block;
  }
}

.field-label__patient-acceptance {
  margin-bottom: 1em;
  display: block;

  strong {
    color: $charcoal;
  }
}
