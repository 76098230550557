// ----------------------------------------------------------------------------
// Appointment Map
// ----------------------------------------------------------------------------

.appointment__map {
  margin-top: rhythm(-5);
}

// ----------------------------------------------------------------------------
// Appointment Media
// ----------------------------------------------------------------------------

.appointment__media {
  @extend %spacing;

  .appointment__title {
    padding-top: rhythm(.375, $h3-font-size);
    margin-bottom: .2em;
  }

  .media-obj {
    width: rhythm(2);
    padding-left: rhythm($space / 2);
    margin-right: rhythm($space * 1.5);

    img {
      @extend %inline-block;
    }
  }
}

.appointment__media--large {
  @include on-desktop {
    .media-obj {
      width: rhythm(3);
      padding-left: rhythm($space * 1.5);
      text-align: right;
    }

    img {
      border-radius: $small-border-radius;
      position: relative;
      margin-top: rhythm(-1);
      margin-left: rhythm(-.25);
      border: rhythm(.25) solid $box-background-color;
    }

    .appointment__title {
      @include adjust-font-size-to($h2-font-size, $h2-line-height);
      padding: 0;
    }
  }
}

// ----------------------------------------------------------------------------
// Appointment Definition List
// ----------------------------------------------------------------------------

.appointment__dl {
  dt,
  dd {
    margin-bottom: rhythm(.5);
  }

  dt {
    @extend %text-right;
    width: rhythm(2);
  }

  dd {
    margin-left: rhythm(3);
  }

  @include on-tablet-down {
    margin-left: rhythm(-$space);
    margin-right: rhythm(-$space);
  }
}

.appointment__dl__divider {
  padding: rhythm($space) 0 0 rhythm($space);

  &:not(:last-child) {
    border-bottom: 1px solid $border-color;
  }

  dt {
    text-align: center;
    padding-left: rhythm($space);
  }

  a {
    @extend .lm-no-letter-spacing;
  }
}

.appointment__dl__divider--extra-padding {
  padding: rhythm($space) rhythm($space * 2);
}

.appointment__dl--large {
  @include on-desktop {
    @include adjust-font-size-to($h3-font-size, $h3-line-height);

    dt,
    dd {
      margin-bottom: rhythm(.5, $h3-font-size);
    }

    dt {
      width: rhythm(3, $h3-font-size);
    }

    dd {
      margin-left: rhythm(4, $h3-font-size);
    }

    h5 {
      font-size: .8em;
    }
  }
}

.appointment__dl__divider__button {
  .lm-button {
    position: relative;
    top: 1px;
  }
}

// ----------------------------------------------------------------------------
// Appointment Box
// ----------------------------------------------------------------------------

.appointment__box {
  background: $input-gray-background-color;
}

// ----------------------------------------------------------------------------
// Verify Appointment Reminder
// ----------------------------------------------------------------------------

h1,
.h1 {
  strong {
    color: $red;
  }
}

.horizontal-divided-list__verification-reminder {
  li {
    font-size: 1.2em;

    &::after {
      position: relative;
      top: -.15em;
      left: .15em;
    }
  }

  a {
    color: $red;

    &:hover {
      text-decoration: underline;
    }
  }
}

.medium {
  font-size: 1.2em;
  line-height: 1.5;
}


