
// ----------------------------------------------------------------------------
// LocalMed Boxes
// ----------------------------------------------------------------------------

.lm-box--no-horizontal-padding {
  padding: rhythm($space) 0;
}

.lm-box--no-vertical-padding {
  padding: 0 rhythm($space);
}

.lm-box--no-padding {
  padding: 0;
}

%lm-box-base {
  @include spacing($full-space);
  padding: rhythm($space);
  border: 1px solid $box-border-color;
  background: $box-background-color;
}

%lm-box {
  @extend %lm-box-base;
  border-radius: $box-border-radius;
  box-shadow: 0 $box-shadow-size 0 $box-shadow-color;

  @media screen and (min-width: $tablet-breakpoint) {
    padding: rhythm($full-space);
  }
}

.lm-box {
  @extend %lm-box;
}

.lm-box--gray {
  background-color: $background-color;
}
