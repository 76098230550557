// ----------------------------------------------------------------------------
// LocalMed Flexible Layouts
// ----------------------------------------------------------------------------

@mixin lm-expandable-column {
  display: table-cell;

  &:not(:last-child) {
    padding-right: rhythm($space) * 1.5;
  }
}

.lm-expandable-column__row {
  display: table;
  width: 100%;
}

.lm-expandable-column__column {
  @extend .lm-bottom-margin;
}

.lm-expandable-column__column--mobile {
  @include lm-expandable-column;
}

@include on-tablet-up {
  .lm-expandable-column__column {
    @include lm-expandable-column;
  }
}

.lm-expandable-column__column--two-column-min {
  width: 46%;
}
