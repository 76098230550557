// ----------------------------------------------------------------------------
// Custom Bullets
// ----------------------------------------------------------------------------

.feature-list-content ul,
.feature-list {
  @include icon-list("ok-sign", $icon-font-size: $h3-font-size);
}

.pretty-content ul,
.arrow-list {
  @include icon-list("arrow-right");
}

.pretty-content ol,
.num-list {
  @include with-feature(generatedcontent) {
    padding-left: rhythm($full-space);
    list-style: none;
    counter-reset: numlist;

    li {
      @extend %spacing;
      position: relative;
      counter-increment: numlist 1;

      &::before {
        border-radius: 1000px;
        position: absolute;
        top: rhythm(($full-space - $num-list-height) / 2, $num-list-font-size);
        left: rhythm(-$full-space, $num-list-font-size);
        display: block;
        width: rhythm($num-list-height, $num-list-font-size);
        height: rhythm($num-list-height, $num-list-font-size);
        background: $num-list-background-color;
        color: $num-list-text-color;
        content: counter(numlist);
        text-align: center;
        font-size: relative-font-size($num-list-font-size);
        line-height: rhythm($num-list-height, $num-list-font-size);
      }

      &.align-num-list-with-button:before {
        top: rhythm(.3, $num-list-font-size);
      }
    }
  }
}
